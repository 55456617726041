import Vue from 'vue';
import Vuex from 'vuex';

import client from '../sdk/client-factory';

Vue.use(Vuex);

/*
Do not redirect user from here (no $router.push). Respond to calling code
with appropriate indicators and let the calling code redirect as needed.
*/

export default new Vuex.Store({
    state: {
        focus: null,
        accountId: null, // account id, set by App.vue for /account routes
        isReady: false, // indicates that we loaded session info from server, so we know if user is authenticated; and if user is authenticated, that we've also loaded user info and account info from server
        serviceInfo: {}, // registration_mode, stripeTokenPublicKey
        // serviceVersion: {}, // version
        session: { isAuthenticated: false }, // userId, isAuthenticated, notAfter, isCsrfGuardEnabled
        user: {}, // name, email, sessionIdleExpiresMillis
        account: null, // account loaded for /account routes
        accountList: null, // null when not loaded, array when loaded; array may be empty if user has no accounts
        interactionMap: {}, // id => interaction ( type: string, next: string, state: object )
        isReturnedFromEtherLink: false, // special flag that is used between interaction.vue and login.vue/signup.vue, to mark that user returned from etherlink and it's ok to auto-submit the email address on the next step (login or signup); because in any other situation it's not ok to auto-submit but we'll pre-fill the email address; we pass this flag here and not on the query parameters so that nobody else can just append "autosubmit=true" to the query and get the same behavior -- we only want it when user comes back from etherlink with an email verification; after this flag is read by the next step (login or signup) it is cleared.
        nav: { queue: [] }, // queue  with items pushed to it, so whenever we are done with something and want to know where to return to, we pop the last item from the queue and go there; and each item can have a function to determine if it's still valid (and the user should be directed there) or if it should be ignored (remove from the queue and proceed to next item)
        loadingMap: { init: true },
        // noteList: [],
    },
    getters: {
        isLoading(state) {
            return Object.values(state.loadingMap).reduce((acc, item) => acc || item, false);
        },
    },
    mutations: {
        ready(state) {
            console.log('vuex store: ready');
            state.isReady = true;
        },
        focus(state, value) {
            state.focus = value;
        },
        setServiceInfo(state, serviceInfo) {
            state.serviceInfo = serviceInfo;
        },
        setSession(state, session) {
            state.session = session;
        },
        user(state, user) {
            state.user = user;
        },
        account(state, account) {
            state.account = account;
        },
        accountList(state, accountList) {
            state.accountList = accountList;
        },
        setInteraction(state, interaction) {
            state.interactionMap = { ...state.interactionMap, ...interaction };
        },
        setNav(state, nav) {
            state.nav = nav;
        },
        loading(state, progress) {
            state.loadingMap = { ...state.loadingMap, ...progress };
        },
        isReturnedFromEtherLink(state, value) {
            state.isReturnedFromEtherLink = value;
        },
        // noteList(state, values) {
        //     state.noteList = [...values];
        // },
    },
    actions: {
        // async createAccount({ commit, dispatch, state }, accountInfo) {
        //     commit('loading', { createAccount: true });
        //     const response = await client.user.create(accountInfo);
        //     if (response.isCreated) {
        //         await dispatch('loadSession');
        //         if (state.session.isAuthenticated) {
        //             await dispatch('loadUser');
        //             // await dispatch('loadAccount');
        //         }
        //     }
        //     commit('loading', { createAccount: false });
        //     return response;
        // },
        async logout({ commit }) {
            commit('loading', { logout: true });
            await client.main().authn.logout();
            // https://vuex.vuejs.org/guide/mutations.html#mutations-follow-vue-s-reactivity-rules
            commit('setSession', { isAuthenticated: false });
            commit('loading', { logout: false });
        },
        // async enableCsrfGuard({ commit, state }) {
        //     const csrfTokenResponse = await client.main().authn.createCsrfToken();
        //     if (csrfTokenResponse.token) {
        //         const csrfGuardToken = csrfTokenResponse.token;
        //         localStorage.setItem('csrfGuardToken', csrfGuardToken);
        //         commit('setSession', { ...state.session, isCsrfGuardEnabled: true, csrfGuardToken });
        //     }
        // },
        async init({ commit, dispatch, state }, { force = false } = {}) {
            if (state.isReady && !force) {
                console.log('vuex store: init already done');
                return;
            }
            console.log('vuex store: init');
            commit('loading', { init: true });
            try {
                await Promise.all([
                    dispatch('loadServiceInfo'),
                    dispatch('loadSession'),
                ]);
                console.log('vuex store: loaded service info and session');
                /*
                if (state.session.isCsrfGuardEnabled && state.session.csrfGuardToken) {
                    // csrf guard enabled, store the token for use by loginshiedl client
                    localStorage.setItem('csrfGuardToken', state.session.csrfGuardToken);
                } else {
                    // enable csrf guard
                    await dispatch('enableCsrfGuard');
                }
                */
                if (state.session.isAuthenticated) {
                    // load data concurrently
                    await Promise.all([
                        dispatch('loadUser'),
                    ]);
                }
                console.log('vuex store: ready');
                commit('ready');
            } catch (err) {
                console.error('vuex store: init failed');
            }
            commit('loading', { init: false });
        },
        async refresh({ dispatch, state, commit }) {
            console.log('vuex store: refresh');
            // not displaying loading bar because we want this to be transparent
            try {
                await dispatch('loadSession', { progressIndicator: false });
                if (state.session.isAuthenticated) {
                    await dispatch('loadUser', { progressIndicator: false });
                    await dispatch('loadAccountList', { progressIndicator: false });
                } else {
                    commit('user', null);
                    commit('accountList', []);
                }
            } catch (err) {
                console.error('vuex store: refresh failed');
            }
        },
        async loadServiceInfo({ commit }) {
            commit('loading', { loadServiceInfo: true });
            try {
                const [/* versionInfo, */serviceInfo] = await Promise.all([
                    // client.service.getVersion(),
                    client.main().service.getInfo(),
                ]);
                // commit('setServiceVersion', versionInfo);
                commit('setServiceInfo', serviceInfo);
            } catch (err) {
                console.error('vuex store: failed to load service info');
            }
            commit('loading', { loadServiceInfo: false });
        },
        async loadSession({ commit, dispatch, state }, { progressIndicator = true } = {}) {
            if (progressIndicator) {
                commit('loading', { loadSession: true });
            }
            try {
                const sessionInfo = await client.main().authn.get();
                console.log(`vuex store: session ${JSON.stringify(sessionInfo)}`);
                const now = Date.now();
                const {
                    user_id: userId,
                    authenticated = false,
                    authenticated_duration: authenticatedDuration = null,
                    unlocked = false,
                    unlocked_duration: unlockedDuration = null,
                    // duration = null,
                    refresh_after_duration: refreshAfterDuration = null,
                    etag = {},
                } = sessionInfo;
                let { reloadTimeoutId } = state.session;
                if (authenticated && typeof refreshAfterDuration === 'number' && refreshAfterDuration > 0) {
                    // clear a previous timeout, if it exists
                    if (reloadTimeoutId) {
                        console.log(`vuex store: clearing timeout ${reloadTimeoutId}`);
                        clearTimeout(reloadTimeoutId);
                    }
                    console.log(`vuex store: scheduling session reload for ${refreshAfterDuration} ms`);
                    reloadTimeoutId = setTimeout(() => {
                        console.log('vuex store: reloading session');
                        dispatch('loadSession');
                    }, refreshAfterDuration);
                }
                commit('setSession', {
                    userId,
                    isAuthenticated: authenticated,
                    isUnlocked: unlocked,
                    authenticatedNotAfter: typeof authenticatedDuration === 'number' && authenticatedDuration > 0 ? now + authenticatedDuration : null,
                    unlockedNotAfter: typeof unlockedDuration === 'number' && unlockedDuration > 0 ? now + unlockedDuration : null,
                    nextRefresh: typeof refreshAfterDuration === 'number' && refreshAfterDuration > 0 ? now + refreshAfterDuration : null,
                    etag,
                    reloadTimeoutId,
                });
            } catch (err) {
                commit('setSession', { fault: { type: 'read-failed' } });
            }
            commit('loading', { loadSession: false });
        },
        async loadUser({ commit, state }, { progressIndicator = true } = {}) {
            try {
                if (progressIndicator) {
                    commit('loading', { loadUser: true });
                }
                const userInfo = await client.user(state.session.userId).user.get();
                commit('user', userInfo);
            } catch (err) {
                commit('user', { fault: { type: 'read-failed' } });
            } finally {
                commit('loading', { loadUser: false });
            }
        },
        async loadAccount({ commit }, { accountId, progressIndicator = true } = {}) {
            try {
                if (progressIndicator) {
                    commit('loading', { loadAccount: true });
                }
                const accountInfo = await client.account(accountId).currentAccount.get();
                commit('account', accountInfo);
            } catch (err) {
                console.error('failed to load account', err);
                commit('account', null);
            } finally {
                commit('loading', { loadAccount: false });
            }
        },
        async loadAccountList({ commit, state }, { progressIndicator = true } = {}) {
            try {
                if (progressIndicator) {
                    commit('loading', { loadAccountList: true });
                }
                const response = await client.user(state.session.userId).user.getAccountList();
                if (response?.list) {
                    commit('accountList', response.list);
                }
            } catch (err) {
                console.error('loadAccountList failed', err);
                commit('accountList', null);
            } finally {
                commit('loading', { loadAccountList: false });
            }
        },
        // async editSession({ commit }, sessionInfo) {
        //     commit('loading', { editSession: true });
        //     let isEdited = false;
        //     try {
        //         const newSessionInfo = await client.main().authn.edit(sessionInfo);
        //         commit('setSession', newSessionInfo);
        //         isEdited = true;
        //     } catch (err) {
        //         console.log('editSession error: %o', err);
        //     }
        //     commit('loading', { editSession: false });
        //     return isEdited;
        // },
        async editCurrentUser({ commit, state }, userInfo) {
            try {
                commit('loading', { editCurrentUser: true });
                const { isEdited } = await client.user(state.session.userId).user.edit(userInfo);
                // https://vuex.vuejs.org/guide/mutations.html#mutations-follow-vue-s-reactivity-rules
                const newUserInfo = { ...state.user, info: { ...state.user.info, ...userInfo } };
                commit('user', newUserInfo);
                return isEdited;
            } catch (err) {
                console.error('editCurrentUser error', err);
                return false;
            } finally {
                commit('loading', { editCurrentUser: false });
            }
        },
        async createInteraction({ commit }, interaction) {
            commit('loading', { createInteraction: true });
            console.log('store: createInteraction %o', interaction);
            const response = await client.main().interaction.create(interaction);
            if (response.id) {
                commit('setInteraction', { [response.id]: response });
            }
            commit('loading', { createInteraction: false });
            return response;
        },
        async editInteraction({ commit }, { interactionId, message }) {
            commit('loading', { editInteraction: true });
            console.log('store: editInteraction %s', interactionId);
            const response = await client.main().interaction.edit(interactionId, message);
            if (response.id) {
                commit('setInteraction', { [response.id]: response });
            }
            commit('loading', { editInteraction: false });
            return response;
        },
        async loadInteraction({ commit }, interactionId) {
            commit('loading', { loadInteraction: true });
            const response = await client.main().interaction.get(interactionId);
            commit('setInteraction', { [interactionId]: response });
            commit('loading', { loadInteraction: false });
            return response;
        },
        queueNavItem({ commit, state }, item) {
            const queue = [...state.nav.queue];
            queue.push({ path: item.path, query: item.query || {}, hash: item.hash || '' });
            commit('setNav', { queue });
        },
        nextNavItem({ commit, state }) {
            try {
                const queue = [...state.nav.queue];
                if (queue.length > 0) {
                    const next = queue.splice(queue.length - 1, 1);
                    commit('setNav', { queue });
                    return next[0];
                }
                if (state.session.isAuthenticated) {
                    return { path: '/dashboard' };
                }
                return { path: '/' };
            } catch (err) {
                console.log('forward error: %o', err);
                return { path: '/' };
            }
        },
    },
});
