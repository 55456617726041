/*!
Copyright (C) 2022 Cygnia. All rights reserved.
*/

// etherlink brandprofile alias at brandprofile.org
const CRYPTIUM_DOCS_BRANDPROFILE = 'cryptiumdocs-M6CGKAR'; // TODO: change the brandprofile to Cygnia (and get a premium brandprofile 'cygnia')

// nodejs cipher names
const CIPHER_AES_256_GCM = 'aes-256-gcm';

// interaction types
const IA_LOGIN = 'login';
const IA_PROFILE_RESPONSE = 'profile_response';
const IA_SERVICE_SETUP = 'service_setup';
const IA_SHARE = 'share';
const IA_SIGNUP = 'signup';
const IA_VERIFY_EMAIL = 'verify_email';

// intent types generally correspond to interaction types but are conceptually different
const INTENT_LOGIN = 'login';
const INTENT_SHARE = 'share';
const INTENT_SIGNUP = 'signup';
const INTENT_PROFILE_RESPONSE = 'profile_response';

// service registration modes
const REGISTRATION_MODE_CLOSED = 'closed';

// roles for access control
const ROLE_SYSTEM_ADMIN = 'system_admin';

// UI routes; these must be synchronized with names declared in router.js in the web application
const ROUTE_FRONT = 'front';
const ROUTE_LOGIN = 'login';
const ROUTE_PROFILE_RESPONSE = 'prompt';
const ROUTE_SHARE = 'portal-vault';
const ROUTE_SIGNUP = 'signup';
const ROUTE_SERVICE_ADMIN = 'service-admin';
const ROUTE_SERVICE_ADMIN_SETUP = 'service-admin-setup';

// service setting names
const SETTING_REGISTRATION_MODE = 'registration_mode';

// token types
const TOKEN_INTERACTION = 'interaction';
const TOKEN_PROFILE_REQUEST = 'profile-request';
const TOKEN_PROFILE_RESPONSE = 'profile-response';

export {
    CRYPTIUM_DOCS_BRANDPROFILE,
    CIPHER_AES_256_GCM,
    IA_LOGIN,
    IA_PROFILE_RESPONSE,
    IA_SERVICE_SETUP,
    IA_SHARE,
    IA_SIGNUP,
    IA_VERIFY_EMAIL,
    INTENT_LOGIN,
    INTENT_PROFILE_RESPONSE,
    INTENT_SHARE,
    INTENT_SIGNUP,
    REGISTRATION_MODE_CLOSED,
    ROLE_SYSTEM_ADMIN,
    ROUTE_FRONT,
    ROUTE_LOGIN,
    ROUTE_PROFILE_RESPONSE,
    ROUTE_SHARE,
    ROUTE_SIGNUP,
    ROUTE_SERVICE_ADMIN,
    ROUTE_SERVICE_ADMIN_SETUP,
    SETTING_REGISTRATION_MODE,
    TOKEN_INTERACTION,
    TOKEN_PROFILE_REQUEST,
    TOKEN_PROFILE_RESPONSE,
};
