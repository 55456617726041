import Vue from 'vue';
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faUser, faEnvelope, faPhone, faCheck, faFile,
    faUserFriends as faUserFriendsSolid,
    faCheckSquare,
    faPlus as faPlusSolid,
    faPlusCircle as faPlusCircleSolid,
    faMinusCircle as faMinusCircleSolid,
    faTrash as faTrashSolid,
    faSave, faCamera,
    faTimes, faSort, faSortDown, faCaretDown, faCaretUp, faCaretRight,
    faBriefcase, faCompress, faDesktop,
    faShieldAlt as faShieldAltSolid,
    faHeart, faUserSecret,
    faBuilding, faFingerprint, faCode, faBars, faUserCircle,
    faPencilAlt, faSearch, faEdit, faInfoCircle,
    faCloudDownloadAlt, faUnlock,
    faShareAlt as faShareAltSolid,
    faPaperPlane as faPaperPlaneSolid,
    faFileSignature as faFileSignatureSolid,
    faEye as faEyeSolid,
    faCog as faCogSolid,
    faChartLine as faChartLineSolid,
    faFolder as faFolderSolid,
    faFolderOpen as faFolderOpenSolid,
    faAddressBook as faAddressBookSolid,
    faWater as faWaterSolid,
    faAngleRight, faAngleLeft, faAngleDoubleRight, faAngleDoubleLeft,
    faMinusSquare, faPaperclip, faArrowLeft,
    faEllipsisV as faEllipsisVSolid,
    faUpload as faUploadSolid,
    faCloudUploadAlt as faCloudUploadAltSolid,
    faExclamationTriangle as faExclamationTriangleSolid,
    faQuestionCircle as faQuestionCircleSolid,
    faQuestion as faQuestionSolid,
    faCircle as faCircleSolid,
    faCheckCircle as faCheckCircleSolid,
    faIdBadge as faIdBadgeSolid,
    faLock as faLockSolid,
    faSlash as faSlashSolid,
    faUserMinus as faUserMinusSolid,
    // faStar as faStarSolid,
    // faStarHalf as faStarHalfSolid,
    faExternalLinkAlt as faExternalLinkAltSolid,
    faChevronLeft as faChevronLeftSolid,
    // for pdf watermark editor
    faGripVertical as faGripVerticalSolid,
    faPalette as faPaletteSolid,
    faSquare as faSquareSolid,
} from '@fortawesome/free-solid-svg-icons';
import {
    faSquare as faSquareRegular,
    faCircle as faCircleRegular,
    faCopy as faCopyRegular,
} from '@fortawesome/free-regular-svg-icons';
import {
    // faStar as faStarLight,
    faCopy as faCopyLight,
} from '@fortawesome/pro-light-svg-icons';
// import {
//     faWordpress as faWordpressBrand,
// } from '@fortawesome/free-brands-svg-icons';
import {
    faEllipsisV as faEllipsisVRegular,
    faCode as farCode,
    faExternalLinkAlt as faExternalLinkAltRegular,
    faSyncAlt as faSyncAltRegular,
    faAngleDoubleRight as faAngleDoubleRightRegular,
} from '@fortawesome/pro-regular-svg-icons';
import {
    faQuestionSquare as faQuestionSquareSolid,
    faFileMinus as faFileMinusSolid,
    // for pdf watermark editor
    faText as faTextSolid,
    faArrowAltUp as faArrowAltUpSolid,
    faArrowAltDown as faArrowAltDownSolid,
    faArrowsAltV as faArrowsAltVSolid,
} from '@fortawesome/pro-solid-svg-icons';

function setup() {
    Vue.component('font-awesome-icon', FontAwesomeIcon);
    Vue.component('font-awesome-layers', FontAwesomeLayers);
    Vue.component('font-awesome-layers-text', FontAwesomeLayersText);
    library.add(
        faUser,
        faEnvelope,
        faPhone,
        faCheck,
        faFile,
        faCheckSquare,
        faSquareRegular,
        faSquareSolid,
        faUserFriendsSolid,
        faPlusSolid,
        faPlusCircleSolid,
        faMinusCircleSolid,
        faTrashSolid,
        faSave,
        faCamera,
        faBriefcase,
        faCompress,
        faDesktop,
        faShieldAltSolid,
        faTimes,
        faSort,
        faSortDown,
        faCaretDown,
        faCaretUp,
        faCaretRight,
        faHeart,
        faBuilding,
        faUserSecret,
        faFingerprint,
        faCode,
        faBars,
        faUserCircle,
        faPencilAlt,
        faSearch,
        faEdit,
        faInfoCircle,
        faCloudDownloadAlt,
        faUnlock,
        faShareAltSolid,
        faPaperPlaneSolid,
        faFileSignatureSolid,
        faEyeSolid,
        faCogSolid,
        faChartLineSolid,
        faFolderSolid,
        faFolderOpenSolid,
        faAddressBookSolid,
        faWaterSolid,
        faAngleRight,
        faAngleLeft,
        faAngleDoubleRight,
        faAngleDoubleLeft,
        faMinusSquare,
        faPaperclip,
        faArrowLeft,
        faEllipsisVSolid,
        faUploadSolid,
        faCloudUploadAltSolid,
        faExclamationTriangleSolid,
        faQuestionCircleSolid,
        faQuestionSquareSolid,
        faQuestionSolid,
        faFileMinusSolid,
        faCircleSolid,
        faCircleRegular,
        faCheckCircleSolid,
        faIdBadgeSolid,
        faLockSolid,
        faSlashSolid,
        faUserMinusSolid,
        faExternalLinkAltSolid,
        faExternalLinkAltRegular,
        faChevronLeftSolid,
        faSyncAltRegular,
        faAngleDoubleRightRegular,
        faCopyRegular,
        faCopyLight,
        // faStarLight, faStarSolid,
        // faStarHalfSolid,
        faEllipsisVRegular,
        farCode,
        // for pdf watermark editor:
        faTextSolid,
        faArrowAltUpSolid,
        faArrowAltDownSolid,
        faArrowsAltVSolid,
        faGripVerticalSolid,
        faPaletteSolid,
    );
}

function mapFontAwesomeIcons(iconMap) {
    const output = {};
    Object.entries(iconMap).forEach(([key, value]) => {
        output[key] = {
            component: FontAwesomeIcon,
            props: {
                icon: value,
            },
        };
    });
    return output;
}

export default { mapFontAwesomeIcons, setup };
