/*!
Copyright (C) 2020 Liberty Infrasystems. All rights reserved.
*/
/* eslint-disable no-console, class-methods-use-this, max-classes-per-file */

const ajax = require('axios');

async function getJson(path, query = null, { requestHeaders = {} } = {}) {
    const response = await ajax.get(path, {
        headers: {
            Accept: 'application/json',
            ...requestHeaders,
        },
        params: query,
    });
    return response.data;
}

/*
async function getOctetStream(path, query = null, { requestHeaders = {} } = {}) {
    const response = await ajax.get(path, {
        headers: {
            Accept: 'application/octet-stream',
            ...requestHeaders,
        },
        params: query,
    });
    return response.data;
}
*/

async function postJsonAcceptJson(path, request, query = null, { requestHeaders = {} } = {}) {
    const response = await ajax.post(path, request ? JSON.stringify(request) : undefined, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            ...requestHeaders,
        },
        params: query,
    });
    return response.data;
}

async function postUpload(path, request, query = null, { requestHeaders = {} } = {}) {
    const response = await ajax.post(path, request, {
        headers: {
            'Content-Type': 'multipart/form-data',
            ...requestHeaders,
        },
        params: query,
    });
    return response.data;
}

class CurrentAccount {
    constructor(context) {
        this.url = `${context.serviceEndpoint}/account/${context.accountId}`;
        this.requestHeaders = context.requestHeaders;
    }

    async get(request) {
        return getJson(`${this.url}`, request, { requestHeaders: this.requestHeaders });
    }

    async delete() {
        return postJsonAcceptJson(`${this.url}/delete/account`, null, null, { requestHeaders: this.requestHeaders });
    }

    async check(request) {
        return postJsonAcceptJson(`${this.url}/check/account`, request, null, { requestHeaders: this.requestHeaders });
    }
}

/**
 * This is a resource class to manage authorized client programs
 */
class Client {
    constructor(context) {
        this.url = `${context.serviceEndpoint}/account/${context.accountId}`;
        this.requestHeaders = context.requestHeaders;
    }

    async create(request) {
        return postJsonAcceptJson(`${this.url}/create/client`, request, null, { requestHeaders: this.requestHeaders });
    }

    async delete(query) {
        return postJsonAcceptJson(`${this.url}/delete/client`, null, query, { requestHeaders: this.requestHeaders });
    }

    /**
     * Search available client tokens assigned to this account
     */
    async search(query) {
        return getJson(`${this.url}/search/client`, query, { requestHeaders: this.requestHeaders });
    }
}

/**
 * This is a resource class to manage authorization tokens for client software
 * using the server's Client API.
 */
class ClientToken {
    constructor(context) {
        this.url = `${context.serviceEndpoint}/account/${context.accountId}`;
        this.requestHeaders = context.requestHeaders;
    }

    async create(request) {
        return postJsonAcceptJson(`${this.url}/create/client-token`, request, null, { requestHeaders: this.requestHeaders });
    }

    async delete(query) {
        return postJsonAcceptJson(`${this.url}/delete/client-token`, null, query, { requestHeaders: this.requestHeaders });
    }

    /**
     * Search available client tokens assigned to this account
     */
    async search(query) {
        return getJson(`${this.url}/search/client-token`, query, { requestHeaders: this.requestHeaders });
    }
}

/*
class Domain {
    constructor(context) {
        this.url = `${context.serviceEndpoint}/account/${context.accountId}`;
        this.requestHeaders = context.requestHeaders;
    }

    async create(request) {
        return postJsonAcceptJson(`${this.url}/create/domain`, request, null, { requestHeaders: this.requestHeaders });
    }

    async get(query) {
        return getJson(`${this.url}/domain`, query, { requestHeaders: this.requestHeaders }); // TODO:  should be ${this.url}/state/domain (need to change server first)
    }

    async edit(query, request) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/edit/domain`, request, query, { requestHeaders: this.requestHeaders });
    }

    async delete(query) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/delete/domain`, null, query, { requestHeaders: this.requestHeaders });
    }

    async list(query) {
        return getJson(`${this.url}/search/domain`, query, { requestHeaders: this.requestHeaders });
    }

    async checkWhois(query) {
        return postJsonAcceptJson(`${this.url}/check/domain-whois`, null, query, { requestHeaders: this.requestHeaders });
    }

    async checkNameservers(query) {
        return postJsonAcceptJson(`${this.url}/check/domain-nameservers`, null, query, { requestHeaders: this.requestHeaders });
    }

    async searchDomainVerification(query) {
        return getJson(`${this.url}/search/domain-verification`, query, { requestHeaders: this.requestHeaders });
    }

    async getVerificationRequest(query) {
        return getJson(`${this.url}/state/domain-verification`, query, { requestHeaders: this.requestHeaders });
    }

    async createVerificationRequest(request) {
        return postJsonAcceptJson(`${this.url}/create/domain-verification`, request, null, { requestHeaders: this.requestHeaders });
    }

    async checkVerification(request) {
        return postJsonAcceptJson(`${this.url}/check/domain-verification`, request, null, { requestHeaders: this.requestHeaders });
    }

    async createDnsRecord(request) {
        return postJsonAcceptJson(`${this.url}/domain/create-dns-record`, request, null, { requestHeaders: this.requestHeaders });
    }

    async editDnsRecord(query, request) {
        return postJsonAcceptJson(`${this.url}/domain/edit-dns-record`, request, query, { requestHeaders: this.requestHeaders });
    }

    async deleteDnsRecord(query) {
        return postJsonAcceptJson(`${this.url}/domain/delete-dns-record`, null, query, { requestHeaders: this.requestHeaders });
    }

    async searchDnsRecord(query) {
        return getJson(`${this.url}/search/dns-record`, query, { requestHeaders: this.requestHeaders });
    }

    async createWebsite(request) {
        return postJsonAcceptJson(`${this.url}/create/website`, request, null, { requestHeaders: this.requestHeaders });
    }

    async editWebsite(query, request) {
        return postJsonAcceptJson(`${this.url}/edit/website`, request, query, { requestHeaders: this.requestHeaders });
    }

    async deleteWebsite(query) {
        return postJsonAcceptJson(`${this.url}/delete/website`, null, query, { requestHeaders: this.requestHeaders });
    }

    async searchWebsite(query) {
        return getJson(`${this.url}/search/website`, query, { requestHeaders: this.requestHeaders });
    }

    async createWebsiteAlias(request) {
        return postJsonAcceptJson(`${this.url}/create/website-alias`, request, null, { requestHeaders: this.requestHeaders });
    }

    async deleteWebsiteAlias(query) {
        return postJsonAcceptJson(`${this.url}/delete/website-alias`, null, query, { requestHeaders: this.requestHeaders });
    }

    async searchWebsiteAlias(query) {
        return getJson(`${this.url}/search/website-alias`, query, { requestHeaders: this.requestHeaders });
    }

    async getDispute(query) {
        return getJson(`${this.url}/state/domain-dispute`, query, { requestHeaders: this.requestHeaders });
    }

    async createDispute(request) {
        return postJsonAcceptJson(`${this.url}/create/domain-dispute`, request, null, { requestHeaders: this.requestHeaders });
    }

    async searchDomainDispute(query) {
        return getJson(`${this.url}/search/domain-dispute`, query, { requestHeaders: this.requestHeaders });
    }
}
*/

/*
class DynamicSharedDomain {
    constructor(context) {
        this.url = `${context.serviceEndpoint}/account/${context.accountId}`;
        this.requestHeaders = context.requestHeaders;
    }

    / * *
     * Search available dynamic shared domains that can be selected by this account
     * /
    async search(query) {
        return getJson(`${this.url}/search/dynamic-shared-domain`, query, { requestHeaders: this.requestHeaders });
    }
}
*/

/*
class DynamicDnsRecord {
    constructor(context) {
        this.url = `${context.serviceEndpoint}/account/${context.accountId}`;
        this.requestHeaders = context.requestHeaders;
    }

    async create(request) {
        return postJsonAcceptJson(`${this.url}/create/dynamic-dns-record`, request, null, { requestHeaders: this.requestHeaders });
    }

    async delete(query) {
        return postJsonAcceptJson(`${this.url}/delete/dynamic-dns-record`, null, query, { requestHeaders: this.requestHeaders });
    }

    / * *
     * Search available dynamic dns records assigned to this account
     * /
    async search(query) {
        return getJson(`${this.url}/search/dynamic-dns-record`, query, { requestHeaders: this.requestHeaders });
    }
}
*/

class LinkAccountUser {
    constructor(context) {
        this.url = `${context.serviceEndpoint}/account/${context.accountId}`;
        this.requestHeaders = context.requestHeaders;
    }

    // TODO: move this to an invitation table; link-account-user record will be created automatically when user accepts invitation
    // async create(request) {
    //     return postJsonAcceptJson(`${this.url}/create/link-account-user`, request, null, { requestHeaders: this.requestHeaders });
    // }

    async delete(query) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/delete/link-account-user`, null, query, { requestHeaders: this.requestHeaders });
    }

    async edit(query, request) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/edit/link-account-user`, request, query, { requestHeaders: this.requestHeaders });
    }

    async search(query) {
        return getJson(`${this.url}/search/link-account-user`, query, { requestHeaders: this.requestHeaders });
    }
}

/**
 * This is used for volume access control; it's in the account context because the volume
 * is owned by the account and the users must already be linked to the account.
 */
/*
class LinkUserVolume {
    constructor(context) {
        this.url = `${context.serviceEndpoint}/account/${context.accountId}`;
        this.requestHeaders = context.requestHeaders;
    }

    async create(request) {
        return postJsonAcceptJson(`${this.url}/create/link-user-volume`, request, null, { requestHeaders: this.requestHeaders });
    }

    async delete(query) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/delete/link-user-volume`, null, query, { requestHeaders: this.requestHeaders });
    }

    async edit(query, request) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/edit/link-user-volume`, request, query, { requestHeaders: this.requestHeaders });
    }

    async search(query) {
        return getJson(`${this.url}/search/link-user-volume`, query, { requestHeaders: this.requestHeaders });
    }
}
*/

/*
class WebsiteTlsCertificate {
    constructor(context) {
        this.url = `${context.serviceEndpoint}/account/${context.accountId}`;
        this.requestHeaders = context.requestHeaders;
    }

    async create(request) {
        return postJsonAcceptJson(`${this.url}/create/website-tls-certificate`, request, null, { requestHeaders: this.requestHeaders });
    }

    async search(query) {
        return getJson(`${this.url}/search/website-tls-certificate`, query, { requestHeaders: this.requestHeaders });
    }
}
*/

/*
// TODO: remove, this is from libertybase
class Form {
    constructor(context) {
        this.url = `${context.serviceEndpoint}/account/${context.accountId}`;
        this.requestHeaders = context.requestHeaders;
    }

    async check(id, request) {
        return postJsonAcceptJson(`${this.url}/check/form`, request, { id }, { requestHeaders: this.requestHeaders });
    }

    async create(request) {
        return postJsonAcceptJson(`${this.url}/create/form`, request, null, { requestHeaders: this.requestHeaders });
    }

    async get(id) {
        return getJson(`${this.url}/state/form`, { id }, { requestHeaders: this.requestHeaders });
    }

    async edit(query, request) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/edit/form`, request, query, { requestHeaders: this.requestHeaders });
    }

    async delete(query) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/delete/form`, null, query, { requestHeaders: this.requestHeaders });
    }

    async search(query) {
        return getJson(`${this.url}/search/form`, query, { requestHeaders: this.requestHeaders });
    }
}
*/

/*
// TODO: remove, this is from libertybase
class FormEntry {
    constructor(context) {
        this.url = `${context.serviceEndpoint}/account/${context.accountId}`;
        this.requestHeaders = context.requestHeaders;
    }

    async check(id, request) {
        return postJsonAcceptJson(`${this.url}/check/form-entry`, request, { id }, { requestHeaders: this.requestHeaders });
    }

    async create(request) {
        return postJsonAcceptJson(`${this.url}/create/form-entry`, request, null, { requestHeaders: this.requestHeaders });
    }

    async get(id) {
        return getJson(`${this.url}/state/form-entry`, { id }, { requestHeaders: this.requestHeaders });
    }

    async edit(query, request) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/edit/form-entry`, request, query, { requestHeaders: this.requestHeaders });
    }

    async delete(query) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/delete/form-entry`, null, query, { requestHeaders: this.requestHeaders });
    }

    async search(query) {
        return getJson(`${this.url}/search/form-entry`, query, { requestHeaders: this.requestHeaders });
    }
}
*/

/*
class Invite {
    constructor(context) {
        this.url = `${context.serviceEndpoint}/account/${context.accountId}`;
        this.requestHeaders = context.requestHeaders;
    }

    async check(id, request) {
        return postJsonAcceptJson(`${this.url}/check/invite`, request, { id }, { requestHeaders: this.requestHeaders });
    }

    async create(request) {
        return postJsonAcceptJson(`${this.url}/create/invite`, request, null, { requestHeaders: this.requestHeaders });
    }

    async get(id) {
        return getJson(`${this.url}/state/invite`, { id }, { requestHeaders: this.requestHeaders });
    }

    async edit(query, request) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/edit/invite`, request, query, { requestHeaders: this.requestHeaders });
    }

    async delete(query) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/delete/invite`, null, query, { requestHeaders: this.requestHeaders });
    }

    async search(query) {
        return getJson(`${this.url}/search/invite`, query, { requestHeaders: this.requestHeaders });
    }
}
*/

/*
// TODO: delete, this is from libertycloud ?  *OR* keep it to create separate file areas with different settings, within the same account
class Volume {
    constructor(context) {
        this.url = `${context.serviceEndpoint}/account/${context.accountId}`;
        this.requestHeaders = context.requestHeaders;
    }

    async check(id, request) {
        return postJsonAcceptJson(`${this.url}/check/volume`, request, { id }, { requestHeaders: this.requestHeaders });
    }

    async create(request) {
        return postJsonAcceptJson(`${this.url}/create/volume`, request, null, { requestHeaders: this.requestHeaders });
    }

    async get(id) {
        return getJson(`${this.url}/state/volume`, { id }, { requestHeaders: this.requestHeaders });
    }

    async edit(query, request) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/edit/volume`, request, query, { requestHeaders: this.requestHeaders });
    }

    async delete(query) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/delete/volume`, null, query, { requestHeaders: this.requestHeaders });
    }

    async list(query) {
        return getJson(`${this.url}/search/volume`, query, { requestHeaders: this.requestHeaders });
    }
}
*/

/*
// TODO: remove, this is from libertycloud
class Website {
    constructor(context) {
        this.url = `${context.serviceEndpoint}/account/${context.accountId}`;
        this.requestHeaders = context.requestHeaders;
    }

    async create(request) {
        return postJsonAcceptJson(`${this.url}/create/website`, request, null, { requestHeaders: this.requestHeaders });
    }

    async get(id) {
        return getJson(`${this.url}/state/website`, { id }, { requestHeaders: this.requestHeaders });
    }

    async edit(query, request) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/edit/website`, request, query, { requestHeaders: this.requestHeaders });
    }

    async delete(query) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/delete/website`, null, query, { requestHeaders: this.requestHeaders });
    }

    async deployConf(request) {
        return postJsonAcceptJson(`${this.url}/rpc/deploy-website-conf`, request, null, { requestHeaders: this.requestHeaders });
    }

    async deployFromVolume(request) {
        return postJsonAcceptJson(`${this.url}/rpc/deploy-website-from-volume`, request, null, { requestHeaders: this.requestHeaders });
    }

    async list(query) {
        return getJson(`${this.url}/search/website`, query, { requestHeaders: this.requestHeaders });
    }
}
*/

class UnicornSprings {
    constructor(context) {
        this.url = `${context.serviceEndpoint}/account/${context.accountId}`;
        this.requestHeaders = context.requestHeaders;
    }

    // quick status -- is there a unicorn springs customer account? is it active? is the user's profile active?
    async check(request) {
        return postJsonAcceptJson(`${this.url}/rpc/check-unicornsprings-customer`, request, null, { requestHeaders: this.requestHeaders });
    }

    // set up unicorn springs customer account and user; responds with isEdited or redirect url
    async setup(request) {
        return postJsonAcceptJson(`${this.url}/rpc/setup-unicornsprings-customer`, request, null, { requestHeaders: this.requestHeaders });
    }

    // more detailed information about an existing unicorn springs account and user
    async report(request) {
        return postJsonAcceptJson(`${this.url}/rpc/report-unicornsprings-customer`, request, null, { requestHeaders: this.requestHeaders });
    }

    // sign in to unicorn springs to set up or manage account; responds with redirect url
    async connect(request) {
        return postJsonAcceptJson(`${this.url}/rpc/connect-unicornsprings-customer`, request, null, { requestHeaders: this.requestHeaders });
    }
}

class Contact {
    constructor(context) {
        this.url = `${context.serviceEndpoint}/account/${context.accountId}`;
        this.requestHeaders = context.requestHeaders;
    }

    async create(request) {
        return postJsonAcceptJson(`${this.url}/create/contact`, request, null, { requestHeaders: this.requestHeaders });
    }

    async get(query) {
        return getJson(`${this.url}/state/contact`, query, { requestHeaders: this.requestHeaders });
    }

    async edit(query, request) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/edit/contact`, request, query, { requestHeaders: this.requestHeaders });
    }

    async check(query, request) {
        return postJsonAcceptJson(`${this.url}/check/contact`, request, query, { requestHeaders: this.requestHeaders });
    }

    async delete(query) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/delete/contact`, null, query, { requestHeaders: this.requestHeaders });
    }

    async search(query) {
        return getJson(`${this.url}/search/contact`, query, { requestHeaders: this.requestHeaders });
    }

    // TODO: change /invite/contact to something else like /invite/share ; and rename method to inviteToDocumentReception;  because we also have a different invitation now for signature requests
    async invite(query, request) {
        return postJsonAcceptJson(`${this.url}/invite/contact`, request, query, { requestHeaders: this.requestHeaders });
    }
}

class File {
    constructor(context) {
        this.url = `${context.serviceEndpoint}/account/${context.accountId}`;
        this.requestHeaders = context.requestHeaders;
    }

    async create(request) {
        return postJsonAcceptJson(`${this.url}/create/file`, request, null, { requestHeaders: this.requestHeaders });
    }

    async get(query) {
        return getJson(`${this.url}/state/file`, query, { requestHeaders: this.requestHeaders });
    }

    async edit(query, request) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/edit/file`, request, query, { requestHeaders: this.requestHeaders });
    }

    async check(query, request) {
        return postJsonAcceptJson(`${this.url}/check/file`, request, query, { requestHeaders: this.requestHeaders });
    }

    async delete(query) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/delete/file`, null, query, { requestHeaders: this.requestHeaders });
    }

    async search(query) {
        return getJson(`${this.url}/search/file`, query, { requestHeaders: this.requestHeaders });
    }

    // query should be { id, mode } where id is the file id, and mode is either 'attach' or 'embed'
    async download(query) {
        // return getOctetStream(`${this.url}/download`, query, { requestHeaders: this.requestHeaders });
        const searchParams = new URLSearchParams();
        Object.entries(query).forEach(([key, value]) => {
            searchParams.set(key, value);
        });
        // TODO: if we are looking for a specific version: Number.isInteger(query.version) { searchParams.set('version', query.version); }
        const querystring = searchParams.toString();
        const fileDownloadURL = `${this.url}/download?${querystring}`;
        const response = await fetch(fileDownloadURL);
        return response.arrayBuffer();
    }

    async upload(query, request) {
        return postUpload(`${this.url}/upload`, request, query, { requestHeaders: this.requestHeaders });
    }

    // TODO: move to link-contact-file
    async share(query, request) {
        return postJsonAcceptJson(`${this.url}/share`, request, query, { requestHeaders: this.requestHeaders });
    }
    // async publish(request) {
    //     return postJsonAcceptJson(`${this.url}/publish/image`, request, null, { requestHeaders: this.requestHeaders });
    // }
}

/**
 * NOTE: there is no 'create' API here because the 'edit' API will insert OR update depending on what is there.
 * This API will NOT edit or delete attributes with the 'hash:' prefix; these are managed automatically by
 * the server. However they can be retrieved with get and search.
 */
class FileAttr {
    constructor(context) {
        this.url = `${context.serviceEndpoint}/account/${context.accountId}`;
        this.requestHeaders = context.requestHeaders;
    }

    async get(query) {
        return getJson(`${this.url}/state/file-attr`, query, { requestHeaders: this.requestHeaders });
    }

    async edit(query, request) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/edit/file-attr`, request, query, { requestHeaders: this.requestHeaders });
    }

    async delete(query) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/delete/file-attr`, null, query, { requestHeaders: this.requestHeaders });
    }

    async search(query) {
        return getJson(`${this.url}/search/file-attr`, query, { requestHeaders: this.requestHeaders });
    }
}

class LinkContactFile {
    constructor(context) {
        this.url = `${context.serviceEndpoint}/account/${context.accountId}`;
        this.requestHeaders = context.requestHeaders;
    }

    async create(request) {
        return postJsonAcceptJson(`${this.url}/create/link-contact-file`, request, null, { requestHeaders: this.requestHeaders });
    }

    async get(query) {
        return getJson(`${this.url}/state/link-contact-file`, query, { requestHeaders: this.requestHeaders });
    }

    async edit(query, request) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/edit/link-contact-file`, request, query, { requestHeaders: this.requestHeaders });
    }

    async check(query, request) {
        return postJsonAcceptJson(`${this.url}/check/link-contact-file`, request, query, { requestHeaders: this.requestHeaders });
    }

    async delete(query) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/delete/link-contact-file`, null, query, { requestHeaders: this.requestHeaders });
    }

    async search(query) {
        return getJson(`${this.url}/search/link-contact-file`, query, { requestHeaders: this.requestHeaders });
    }
}

class Watermark {
    constructor(context) {
        this.url = `${context.serviceEndpoint}/account/${context.accountId}`;
        this.requestHeaders = context.requestHeaders;
    }

    async create(request) {
        return postJsonAcceptJson(`${this.url}/create/watermark`, request, null, { requestHeaders: this.requestHeaders });
    }

    async get(query) {
        return getJson(`${this.url}/state/watermark`, query, { requestHeaders: this.requestHeaders });
    }

    async edit(query, request) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/edit/watermark`, request, query, { requestHeaders: this.requestHeaders });
    }

    async delete(query) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/delete/watermark`, null, query, { requestHeaders: this.requestHeaders });
    }

    async search(query) {
        return getJson(`${this.url}/search/watermark`, query, { requestHeaders: this.requestHeaders });
    }

    // marks the most recent draft as published, so it will apply to future downloads by contacts; there's no "unpublish", but the watermark can be deleted if no files are referencing it; to remove a watermark from a document you have to edit the file attributes to remove the watermark_id attribute
    async publish(query) {
        return postJsonAcceptJson(`${this.url}/publish/watermark`, null, query, { requestHeaders: this.requestHeaders });
    }
}

class SignatureDocument {
    constructor(context) {
        this.url = `${context.serviceEndpoint}/account/${context.accountId}`;
        this.requestHeaders = context.requestHeaders;
    }

    async create(request) {
        return postJsonAcceptJson(`${this.url}/create/signature-document`, request, null, { requestHeaders: this.requestHeaders });
    }

    async check(query, request) {
        return postJsonAcceptJson(`${this.url}/check/signature-document`, request, query, { requestHeaders: this.requestHeaders });
    }

    async get(query) {
        return getJson(`${this.url}/state/signature-document`, query, { requestHeaders: this.requestHeaders });
    }

    async edit(query, request) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/edit/signature-document`, request, query, { requestHeaders: this.requestHeaders });
    }

    async delete(query) {
        return postJsonAcceptJson(`${this.url}/delete/signature-document`, null, query, { requestHeaders: this.requestHeaders });
    }

    async search(query) {
        return getJson(`${this.url}/search/signature-document`, query, { requestHeaders: this.requestHeaders });
    }

    async invite(request) {
        return postJsonAcceptJson(`${this.url}/invite/signature-document`, request, null, { requestHeaders: this.requestHeaders });
    }
}

/**
 * This is a client for the browser and it uses session cookies to authenticate to the server.
 */
class BrowserClient {
    constructor(context = {}) {
        this.currentAccount = new CurrentAccount(context);
        this.client = new Client(context);
        this.clientToken = new ClientToken(context);
        // this.domain = new Domain(context);
        // this.dynamicSharedDomain = new DynamicSharedDomain(context);
        // this.dynamicDnsRecord = new DynamicDnsRecord(context);
        // this.form = new Form(context);
        // this.formEntry = new FormEntry(context);
        this.contact = new Contact(context);
        this.file = new File(context);
        this.fileAttr = new FileAttr(context);
        // this.invite = new Invite(context);
        this.linkAccountUser = new LinkAccountUser(context);
        this.linkContactFile = new LinkContactFile(context);
        // this.linkUserVolume = new LinkUserVolume(context); // TODO: delete
        // this.volume = new Volume(context); // TODO: delete
        // this.website = new Website(context); // TODO: delete
        // this.websiteTlsCertificate = new WebsiteTlsCertificate(context); // TODO: delete
        this.signatureDocument = new SignatureDocument(context);
        this.unicornsprings = new UnicornSprings(context);
        this.watermark = new Watermark(context);
    }
}

export default BrowserClient;

export {
    CurrentAccount,
    Client,
    ClientToken,
    // Domain,
    // DynamicSharedDomain,
    // DynamicDnsRecord,
    Contact,
    File,
    FileAttr,
    // Form, // TODO: remove this, it's from libertybase
    // FormEntry, // TODO: remove this, it's from libertybase
    // Invite,
    LinkAccountUser,
    LinkContactFile,
    // LinkUserVolume, // TODO: remove this, it's from libertycloud
    // Volume, // TODO: remove this, it's from libertycloud
    // Website, // TODO: remove this, it's from libertycloud
    // WebsiteTlsCertificate, // TODO: remove this, it's from libertycloud
    SignatureDocument,
    UnicornSprings,
    Watermark,
};
